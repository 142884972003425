.LCP-image {
  background-image: url("./assets/placeholder-image.webp");
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.img- {
  min-width: 32px;
  min-height: 32px;
  /* Ensure consistent dimensions */
  object-fit: cover;
}

.skeleton-loader {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right,
      #f0f0f0 0%,
      #e0e0e0 20%,
      #f0f0f0 40%,
      #f0f0f0 100%);
  background-size: 200% 100%;
}

.scrollbar-mob::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

.scrollbar-mob::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.scrollbar-mob::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollbar-mob::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}


@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: 200px 0;
  }
}