@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

/* Character Styles */
/* .unnamed-character-style-1 {
    font-family: var(--unnamed-font-family-open-sans);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-12);
    line-height: var(--unnamed-line-spacing-17);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-1a1a1a);
}

.unnamed-character-style-2 {
    font-family: var(--unnamed-font-family-open-sans);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-600);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-5f6368);
}

.unnamed-character-style-3 {
    font-family: var(--unnamed-font-family-open-sans);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-5f6368);
} */

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
}

.open-sans-custom {
  font-family: "Open Sans", sans-serif;
  /* Ensure to use sans-serif as a fallback */
  font-optical-sizing: auto;
  font-weight: 400;
  /* Replace with the desired weight (300 to 800) */
  font-style: normal;
  font-variation-settings: "wdth" 100;
  /* You can adjust this value as needed */
}

.open-sans-300 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.open-sans-400 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.open-sans-600 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.open-sans-700 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

/* Add more as needed */

:root {
  --black: #1a1a1a;
  --red: #ff3d00;
  --grey: #e2e7ee;
  --lightGrey: #f7f8fa;
  --pureGrey: #00000029;
  --white: #fff;
  --darkGrey: #45505f;
}

h1 {
  font-size: 2.5rem;
  /* Example size for h1 */
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #000;
}

h2 {
  font-size: 2rem;
  /* Example size for h2 */
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #000;
}

h3 {
  font-size: 1.5rem;
  /* Example size for h3 */
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: var(--white);
}

h4 {
  font-size: 1.2rem;
  /* Example size for h4 */
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  /* color: var(--white); */
  color: #1a1a1a;
}

h5 {
  font-size: 1rem;
  /* Example size for h5 */
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: var(--white);
}

h6 {
  font-size: 0.8rem;
  /* Example size for h6 */
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: var(--white);
}

/* Add this to your global CSS file or Tailwind CSS custom CSS file */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Add this to your global CSS file or Tailwind CSS custom CSS file */
.scrollbar-transparent::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-transparent::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.scrollbar-transparent::-webkit-scrollbar-track {
  background-color: transparent;
}

/* *********************************** CSS used for classes ********************************************* */

/* input filed css */
.input-container {
  position: relative;
  /* margin-bottom: 1.5rem; */
}

.input-container input {
  width: 100%;
  padding: 0.75rem 0.75rem;
  border: 1.5px solid #45505f;
  border-radius: 0.75rem;
  font-size: 1rem;
  outline: none;
  background-color: transparent;
  color: #45505f;
  transition: border-color 0.3s ease-in-out;
  box-sizing: border-box;
  /* Ensures padding is included in width */
}

.input-container input:focus {
  border-color: #45505f;
  /* Blue color on focus */
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 0.25rem;
  font-size: 1rem;
  color: #6b7280;
  /* Gray color */
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  /* Ensures label does not interfere with input interaction */
  z-index: 1;
  /* Ensures label stays above input */
}

.input-container input:focus+label,
.input-container input:not(:placeholder-shown)+label {
  top: -0.1rem;
  /* Moves label up */
  left: 0.75rem;
  font-size: 0.75rem;
  /* Smaller font size */
  color: #45505f;
  /* Blue color when focused or filled */
}

/* input backgroud css not changes after google suggetion css */
.input-container input {
  background-color: transparent !important;
}

.input-container input:focus {
  background-color: transparent !important;
}

@layer utilities {
  .input-container input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-text-fill-color: black !important;
  }

  .input-container input:-moz-autofill {
    background-color: transparent !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    -moz-text-fill-color: black !important;
  }
}

/* slect css */
/* .peer:focus ~ label,
.peer:not([value=""]) ~ label {
  top: 0; 
  font-size: 0.75rem;
  color: #3b82f6; 
  background-color: white; 
  padding: 0 0.25rem; 
  transform: translateY(-50%);
} */

/*  */

.bg_home {
  width: 100%;
  height: auto;
  /* background-color: var(--lightGrey); */
  color: var(--black);
  /* min-height: 100vh; */
  min-height: auto;
  /* opacity: 0.8; */
  /* font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; */
}

.date_shown {
  color: var(--darkGrey);
  font-size: 10px;
}

.title_shown {
  color: var(--black);
  font-size: 16px;
}

.subtitle_shown {
  color: var(--black);
  font-size: 12px;
}

.recme_shown {
  color: var(--darkGrey);
  text-align: left;
  font: normal normal normal 12px/18px Open Sans;
  letter-spacing: 0px;
  color: #45505f;
  opacity: 1;
}

.web-shadow {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8));
  -webkit-mask-image: linear-gradient(to right,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.8));
}

.mobile-shadow-bottom {
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7));
  -webkit-mask-image: linear-gradient(to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 2));
}

/* poll prediction css */
.css-bapulm-MuiSlider-root .MuiSlider-track {
  background-color: gray !important;
  border: none;
  outline: none;
}

.css-bapulm-MuiSlider-root .MuiSlider-rail {
  background-color: gray !important;
}

.css-bapulm-MuiSlider-root {
  height: 0 !important;
  padding: 7px 0 !important;
}

.css-bapulm-MuiSlider-root .MuiSlider-thumb {
  height: 50px !important;
  border: 1px solid gray !important;
  border-radius: 0% !important;
  outline: none;
}

/* toaster msg css */
.alert-dismissible .btn-close {
  position: absolute;
  right: 4px;
  top: 4px;
  /* Added top positioning to align it correctly */
}

.toaster-show {
  position: fixed;
  top: 40px;
  right: 10px;
  z-index: 99999;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.toaster-show.success {
  background: #bbe5d7 !important;
}

.toaster-show.error {
  background: #fff4f4 !important;
  color: #ff8a8a;
}

.btn-close {
  padding: 0.25rem 0.5rem !important;
  opacity: 1 !important;
  /* Corrected opacity to a valid range */
  background: none;
  /* Ensure there's no background by default */
  border: none;
  /* Remove any default border */
  cursor: pointer;
}

.css-1eu0ex0 .MuiSlider-rail,
.css-1eu0ex0 .MuiSlider-track {
  width: 0 !important;
  background-color: transparent !important;
}

/* filed error msg css */
.error-message {
  font-size: 12px;
  color: red;
}

.second-last-css\:border-none:nth-last-of-type(2) {
  border: none !important;
}

/* post details style  */

.black-heading>figure,
table {
  width: 100%;
  /* border: 2px solid lightgray; */
  margin: 0.5rem 0;
}

.black-heading>p {
  color: #45505f;
  margin: 7px 0;
}

.black-heading>ul>li {
  list-style: disc;
  margin: 0.3rem 1.5rem;
  color: #45505f;
}

.black-heading>ol>li {
  list-style: disc;
  margin: 0.3rem 1.5rem;
  color: #45505f;
}

.black-heading>ul>li>ul>li {
  list-style: circle;
  margin: 0.3rem 1rem;
  color: #45505f;
}

.black-heading>figure>table>tbody>tr>td {
  padding: 10px;
  border: 2px solid #012169;
  /* min-width: 15rem; */
  font-weight: 300;
  text-align: center;
}

.black-heading>figure>table>thead>tr {
  border: 2px solid #012169;
}

.black-heading>figure>table>thead>tr>th {
  border: 1px solid;
  font-weight: 400;
  color: white;
  background-color: #012169;
  padding: 8px;
}

.black-heading>figure>table>thead>tr>th>strong {
  font-weight: 400;
  text-transform: capitalize;
}

.black-heading>h2 {
  margin-top: 30px;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #000;
  font-weight: 500;
}

/* @media screen and (min-width: 480px) {} */

/* .black-heading>figure {
  width: 100%;
  border: 5px solid black;
} */
.table {
  margin: 1.5rem 0;
  /* border: 5px solid; */
  /* width: 50%; */
  overflow: auto;
}

/* .black-heading>figure,
td {
  border: 1px solid lightgray;
  padding: 8px;
} */

/* switch btn css */
.switch-container {
  display: inline-block;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  display: inline-block;
  width: 30px;
  height: 19px;
  background-color: #ccc;
  border-radius: 30px;
  border: 2px solid black;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.switch-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  width: 16px;
  height: 16px;
  border: 2px solid black;
  background-color: black;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}

.switch-checkbox:checked+.switch-label {
  background-color: transparent;
  border-color: black;
}

.switch-checkbox:checked+.switch-label::before {
  transform: translate(20px, -50%);
}

.circle {
  position: relative;
  background: #00b01d;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}

.checkMark {
  position: absolute;
  transform: rotate(50deg) translate(-50%, -50%);
  left: 27%;
  top: 43%;
  height: 6px;
  width: 6px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.text-manage {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/* Ensure no border for the last and second-to-last items */
.grid>a:nth-last-child(1)>div,
.grid>a:nth-last-child(2)>div {
  border-bottom: none;
}

/* model css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-heading>p {
  min-height: 1rem;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 80%;
  max-width: 500px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.nav-link-hover {
  position: relative;
  display: inline-block;
  /* color: inherit;  */
  text-decoration: none;
}

.nav-link-hover-poll {
  position: relative;
  display: inline-block;
  /* color: inherit;  */
  text-decoration: none;
}

.nav-link-hover-poll:hover {
  color: #1a1a1a;
  transition: transform 0.3s ease;
}

.nav-link-hover-poll::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 5px;
  height: 3px;
  width: 100%;
  background-color: #e4002b;
  /* background-color: #FF3D00; */
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link-hover-poll:hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 5px;
  height: 3px;
  width: 100%;
  /* background-color: #E4002B; */
  background-color: #ff3d00;
  transform: scaleX(1);
  transition: transform 0.3s ease;
}

.nav-link-hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: #e4002b;
  /* background-color: #FF3D00; */
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link-hover:hover::after {
  transform: scaleX(1);
}

.current-page {
  position: relative;
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

.current-page::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 6px;
  width: 100%;
  border-radius: 25px;
  background-color: #ff3d00;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform: scaleX(1);
}

/* search mode slider */
.transition-transform {
  transition: transform 0.3s ease-in-out;
}